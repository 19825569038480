import { DecoderFunction, union } from 'typescript-json-decoder';

export enum SignatureStatus {
  WAITING = 'WAITING',
  SIGNED = 'SIGNED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  RESERVED = 'RESERVED',
  CONTACT_INFORMATION_MISSING = 'CONTACT_INFORMATION_MISSING',
  BLOCKED = 'BLOCKED',
  NAME_NOT_AVAILABLE = 'NAME_NOT_AVAILABLE',
  SIGNING_FAILED = 'SIGNING_FAILED',
}
export const signatureStatusDecoder: DecoderFunction<SignatureStatus> = (
  value
) => {
  return union(...Object.values(SignatureStatus))(value);
};

export enum SignatureType {
  ADVANCED_E_SIGNATURE = 'ADVANCED_E_SIGNATURE',
  AUTHENTICATED_E_SIGNATURE = 'AUTHENTICATED_E_SIGNATURE',
}
export const signatureTypeDecoder: DecoderFunction<SignatureType> = (value) => {
  return union(...Object.values(SignatureType))(value);
};
