import { QueryClient } from 'react-query';
import { ApiError } from './api';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount: number, error: unknown) => {
          const apiError = error as ApiError<unknown>;
          if (apiError?.statusCode && apiError.statusCode < 500) {
            return false;
          }
          return failureCount >= 2;
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 30 * 1000,
      },
    },
  });
