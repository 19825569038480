import { FunctionComponent } from 'react';
import { addClassesFromProps } from '@common/basicFunctions';
import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import Button, { ButtonProps } from './Button';
import { ButtonStyle, buttonStyles } from './buttonAttributes';

const ButtonWithSpinner: FunctionComponent<
  ButtonProps & { isLoading: boolean } & { buttonStyle?: ButtonStyle }
> = ({
  isLoading,
  buttonStyle = buttonStyles.primary,
  extraClassName,
  children,
  ...commonProps
}) => {
  return isLoading ? (
    <Button
      dataTestid="spinner-button"
      extraClassName={`spinner-button${addClassesFromProps(extraClassName)}`}
      disabled={true}
      {...commonProps}
    >
      <SpinnerHW />
    </Button>
  ) : (
    <Button
      extraClassName={extraClassName}
      buttonStyle={buttonStyle}
      {...commonProps}
    >
      {children}
    </Button>
  );
};

export default ButtonWithSpinner;
