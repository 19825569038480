import { FunctionComponent, useCallback, useEffect } from 'react';

import FocusableElement from '../focus/FocusableElement';
import { useTracking } from '../analytics/Analytics';
import { addClassesFromProps } from '../basicFunctions';
import { App } from '../../apps';
import { useFocus } from '../focus/AppWithFocus';
import { useI18n } from '../language/i18n';

export enum ImagePosition {
  top = 'top',
  bottom = 'bottom',
  none = 'none',
}

const errorMessage = 'error-message';

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  message,
  heading,
  imagePosition = ImagePosition.bottom,
  wrapperClassName,
  isInTable,
  tracking,
  dataTestid,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const { setFocus } = useFocus();
  const track = useCallback(
    (label: string) => {
      if (tracking) {
        registerAnalyticsEvent({
          category: tracking.app,
          action: tracking.action ? tracking.action : '',
          label: label,
        });
      }
    },
    [tracking, registerAnalyticsEvent]
  );

  useEffect(() => {
    track('Feilmelding vist');
    setFocus(errorMessage, true);
  }, [track, setFocus]);

  return (
    <FocusableElement
      id={errorMessage}
      dataTestid={dataTestid}
      className={`box box-error standard-error${addClassesFromProps(
        wrapperClassName
      )}`}
      isInTable={isInTable}
    >
      {imagePosition === ImagePosition.top && (
        <div className="img img-breven-grumpy file-icon-big file-icon-error" />
      )}
      <div className="invisible-div error-message-text hw-block--mb">
        <h1
          className={`hw-text-title hw-block--mb
              ${
                imagePosition === ImagePosition.bottom
                  ? ' font-size-xl'
                  : ' font-size-l'
              }
            `}
        >
          {heading ?? translation.common.error.header}
        </h1>
        <p className="error-message" data-testid="error-message-text">
          {message ?? translation.common.error.generic}
        </p>
      </div>
      {imagePosition === ImagePosition.bottom && (
        <div className="img img-breven-grumpy file-icon-big file-icon-error" />
      )}
    </FocusableElement>
  );
};

interface ErrorMessageProps {
  message?: string;
  heading?: string;
  imagePosition?: ImagePosition;
  wrapperClassName?: string;
  isInTable?: boolean;
  tracking?: {
    app: App;
    action?: string;
  };
  dataTestid?: string;
}

export default ErrorMessage;
