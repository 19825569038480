import velocity from 'velocity-animate';

import config from '../config';

const scrollTo = (x: number, y: number) => {
  if (config.enableScroll) {
    window.scrollTo(x, y);
  }
};

const animate = (domNode: HTMLElement, offset: number) => {
  if (config.enableScroll) {
    velocity.animate(domNode, 'scroll', {
      offset,
      duration: 800,
      easing: 'ease',
    });
  }
};

export default { scrollTo, animate };
