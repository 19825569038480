import {
  DecoderFunction,
  decodeType,
  nil,
  undef,
  union,
} from 'typescript-json-decoder';

export const maybeDecoder = <T>(decoder: DecoderFunction<T>) => {
  return (value: unknown) => {
    const maybeValue = union(decoder, undef, nil)(value);
    return maybeValue === null || maybeValue === undefined
      ? undefined
      : maybeValue;
  };
};

export const maybeDecoderWithDefault = <T>(
  decoder: DecoderFunction<T>,
  def: decodeType<T>
) => {
  return (value: unknown) => {
    return maybeDecoder(decoder)(value) || def;
  };
};

export const exactValueDecoder = <T, E extends T>(
  decoder: DecoderFunction<T>,
  exact: E
) => {
  return (value: unknown) => {
    if (decoder(value) === exact) {
      return exact;
    } else {
      throw `String \`${value as string}\` does not have the exact value \`${
        exact as unknown as string
      }\``;
    }
  };
};
