import { FunctionComponent, useMemo } from 'react';
import ElectronicIdProvider, { ProviderData } from './ElectronicIdProvider';

const ElectronicIdProviderList: FunctionComponent<
  ElectronicIdProviderListProps
> = ({ onClick, electronicProviders }) => {
  const sortedElectronicEidProviders = useMemo(() => {
    return [
      ...electronicProviders.filter(
        (provider) => !(provider.eid === 'mobile_bankid')
      ),
      ...electronicProviders.filter(
        (provider) => provider.eid === 'mobile_bankid'
      ),
    ];
  }, [electronicProviders]);

  return (
    <ul className="hw-list--no-bullets eid-choices">
      {sortedElectronicEidProviders.map((providerData) => (
        <ElectronicIdProvider
          key={providerData.eid}
          onClick={() => onClick(providerData.eid)}
          {...providerData}
        />
      ))}
    </ul>
  );
};

interface ElectronicIdProviderListProps {
  onClick: (eid: string) => void;
  electronicProviders: Array<ProviderData>;
}

export default ElectronicIdProviderList;
