import { stopEventPropagation } from './InfoBoxButton';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Collapse } from 'react-collapse';

const InfoBoxWithVisibilityToggle: FunctionComponent<
  InfoBoxWithVisibilityToggleProps & PropsWithChildren
> = ({ visibility, children }) => (
  <Collapse isOpened={visibility}>
    <div className="infobox-collapse-wrapper">
      <div
        className="infobox-frame"
        onClick={stopEventPropagation}
        role="presentation"
      >
        {children}
      </div>
    </div>
  </Collapse>
);

interface InfoBoxWithVisibilityToggleProps {
  visibility: boolean;
}

export default InfoBoxWithVisibilityToggle;
