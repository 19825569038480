import { FunctionComponent } from 'react';
import { ChevronRightIcon, IconSize } from '@hedwig/icons';

interface ElectronicIdProviderProps {
  onClick: () => void;
  initialFocus?: boolean;
}

export interface ProviderData {
  eid: string;
  eidExplanation: string;
  eidHeading: string;
  imageClass: string;
}

const ElectronicIdProvider: FunctionComponent<
  ElectronicIdProviderProps & ProviderData
> = ({
  eid,
  onClick,
  imageClass,
  eidHeading,
  eidExplanation,
  initialFocus = false,
}) => {
  const isBankIDMobile = eid === 'mobile_bankid';

  const icon = (
    <img className="eid-logo" src={imageClass} alt="" aria-hidden="true" />
  );
  const isNotDesktop = () => {
    const android = () => /Android/i.exec(navigator.userAgent);
    const blackBerry = () => /BlackBerry/i.exec(navigator.userAgent);
    const iOS = () => /iPhone|iPad|iPod/i.exec(navigator.userAgent);
    const opera = () => /Opera Mini/i.exec(navigator.userAgent);
    const windows = () => /IEMobile/i.exec(navigator.userAgent);
    return android() || blackBerry() || iOS() || opera() || windows();
  };

  return (
    <li
      id={eid}
      className={`eid-choice__wrapper ${
        eid === 'buypass' && isNotDesktop() ? 'hidden' : ''
      }`}
    >
      <a
        ref={(domElement) => {
          if (initialFocus && domElement) domElement.focus();
        }}
        id={`eid-link-${eid}`}
        data-testid={`eid-button-${eid}`}
        href="#"
        className="image-link eid-choice__link"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        tabIndex={0}
      >
        <div className="eid-choice__text">
          {!isBankIDMobile && <div>{icon}</div>}
          <div className="info-eid-choice">
            <p className="eid-provider-name">{eidHeading}</p>
            {!isBankIDMobile && (
              <p className="eid-provider-description">{eidExplanation}</p>
            )}
          </div>
        </div>
        <ChevronRightIcon
          size={IconSize.SMALL}
          extraClassName="eid-choice__chevron ui-medmax"
        />
      </a>
    </li>
  );
};

export default ElectronicIdProvider;
