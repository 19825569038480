import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '../basicFunctions';

import './page-building-blocks.less';

const PageContainer: FunctionComponent<
  PageContainerProps & PropsWithChildren
> = ({ children, slim, extraClassName }) => (
  <div
    className={`hw-container page-container${
      slim ? ' hw-container--slim' : ''
    }${addClassesFromProps(extraClassName)}`}
  >
    {children}
  </div>
);

interface PageContainerProps {
  slim?: boolean;
  extraClassName?: string;
}

export default PageContainer;
