import { useEffect, useState } from 'react';

export enum SignicatIframeStatus {
  START = 'start',
  SUCCESS = 'success',
  CANCEL = 'cancel',
}

const useSignicatIframe = () => {
  const [currentIframeStatus, setCurrentIframeStatus] = useState(
    SignicatIframeStatus.START
  );

  useEffect(() => {
    window.signicat = (iframeStatus: SignicatIframeStatus) => {
      setCurrentIframeStatus(iframeStatus);
      delete window.signicat;
    };
  }, [setCurrentIframeStatus]);

  return currentIframeStatus;
};

export default useSignicatIframe;
