import { FunctionComponent, useEffect, useRef, PropsWithChildren } from 'react';

import scroller from '../navigation/scroller';
import { useFocus } from './AppWithFocus';

const headerHeight = -48;
const headerHeightJobsTable = -108;

interface FocusableElementProps {
  className?: string;
  id: string;
  isInTable?: boolean;
  dataTestid?: string;
}

const FocusableElement: FunctionComponent<
  FocusableElementProps & PropsWithChildren
> = ({ className, id, isInTable = false, children, dataTestid }) => {
  const focusableDomNode = useRef<HTMLDivElement>(null);
  const focus = useFocus();

  useEffect(() => {
    if (focus.id === id) {
      if (focus.withScroll && focusableDomNode.current) {
        scroller.animate(
          focusableDomNode.current,
          isInTable ? headerHeightJobsTable : headerHeight
        );
      }
      focusableDomNode?.current?.focus();
      focus.setFocus('');
    }
  }, [focus, id, isInTable, focusableDomNode]);

  return (
    <div
      className={className}
      id={id}
      tabIndex={-1}
      ref={focusableDomNode}
      aria-live="polite"
      data-testid={dataTestid}
    >
      {children}
    </div>
  );
};

export default FocusableElement;
