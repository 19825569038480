import { Translation } from '../language/i18n';

export enum HelpTopicUrl {
  aboutPosten = 'om-posten-signering',
  send = 'sende',
  sign = 'signere',
}

export const topicToTextMap = (
  translation: Translation['common']['help']['main_links'],
  link: HelpTopicUrl
) =>
  ({
    [HelpTopicUrl.aboutPosten]: translation.about,
    [HelpTopicUrl.send]: translation.send,
    [HelpTopicUrl.sign]: translation.sign,
  }[link]);
