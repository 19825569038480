import bankId from '@images/logos/bankid-indigo-large.svg';
import bankIdMobile from '@images/logos/bankid-mobil_pos.webp';
import buypass from '@images/logos/buypass.svg';
import { Translation } from '../language/i18n';

export const bankIDSignering = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.bankid_signer_heading,
  eidExplanation: translation.bankid_explanation,
  imageClass: bankId,
  eid: 'bankid',
  initialFocus: true,
});
export const bankIDmobilSignering = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.mobile_bankid_signer_heading,
  eidExplanation: translation.mobile_bankid_explanation,
  imageClass: bankIdMobile,
  eid: 'mobile_bankid',
  initialFocus: false,
});
export const buyPassSignering = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.buypass_signer_heading,
  eidExplanation: translation.buypass_explanation,
  imageClass: buypass,
  eid: 'buypass',
});
export const bankIDLogin = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.bankid_login_heading,
  eidExplanation: translation.bankid_explanation,
  imageClass: bankId,
  eid: 'bankid',
  initialFocus: true,
});
export const mobilBankIDLogin = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.mobile_bankid_login_heading,
  eidExplanation: translation.mobile_bankid_explanation,
  imageClass: bankIdMobile,
  eid: 'mobile_bankid',
  initialFocus: false,
});
export const buyPassLogin = (
  translation: Translation['common']['electronic_id']['eid']
) => ({
  eidHeading: translation.buypass_login_heading,
  eidExplanation: translation.buypass_explanation,
  imageClass: buypass,
  eid: 'buypass',
});
