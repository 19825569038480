import { useCallback, useState } from 'react';
import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';

const useUserActivity = (
  idleTimeout = 1000 * 60,
  eventsThrottle = 1000 * 3,
  throttle = 1000 * 3
) => {
  const [isActive, setIsActive] = useState(true);

  const onIdle = useCallback(() => {
    setIsActive(false);
  }, []);

  const onActive = useCallback(() => {
    setIsActive(true);
  }, []);

  useReactIdleTimer({
    onIdle,
    onActive,
    crossTab: true,
    timeout: idleTimeout,
    eventsThrottle,
    throttle,
  });

  return { isActive };
};

export default useUserActivity;
