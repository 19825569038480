import { FunctionComponent, MouseEvent } from 'react';
import { useTracking } from '../../analytics/Analytics';
import { useI18n } from '../../language/i18n';
import { HelpIcon, IconColor, TimesCircleIcon } from '@hedwig/icons';
import { addClassesFromProps } from '../../basicFunctions';
import { App } from '../../../apps';

export const stopEventPropagation = (
  event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
): void => {
  event.nativeEvent.stopImmediatePropagation();
  event.preventDefault();
};

interface InfoBoxButtonProps {
  onClick: () => void;
  visibility: boolean;
  name: string;
  app?: App;
  extraClassName?: string;
}

const InfoBoxButton: FunctionComponent<InfoBoxButtonProps> = ({
  onClick,
  visibility,
  name,
  app,
  extraClassName,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  return (
    <button
      type="button"
      aria-expanded={visibility}
      aria-label={translation.uu.infobox.label}
      className={`button-default-disable${addClassesFromProps(extraClassName)}`}
      onClick={(event) => {
        stopEventPropagation(event);
        if (!visibility) {
          registerAnalyticsEvent({
            category: app || '',
            action: 'clicked-on-info-button',
            label: `${name}-info-box`,
          });
        }
        onClick();
      }}
    >
      {visibility ? (
        <TimesCircleIcon
          color={IconColor.PRIMARY_DARKER}
          extraClassName="icon-with-text--left help-tip__icon"
        />
      ) : (
        <HelpIcon
          color={IconColor.PRIMARY_DARKER}
          extraClassName="icon-with-text--left help-tip__icon"
        />
      )}
    </button>
  );
};

export default InfoBoxButton;
