import { FunctionComponent } from 'react';

import { addClassesFromProps } from '@common/basicFunctions';
import { ErrorIcon, IconSize } from '../icons';

const ButtonErrorMessage: FunctionComponent<{
  errorMessage: string;
  ariaDescribedBy: string;
  extraClassName?: string;
  dataTestId?: string;
}> = ({ errorMessage, ariaDescribedBy, extraClassName, dataTestId }) => {
  return (
    <p
      className={`hw-text-smaller hw-block--mt-small-1${addClassesFromProps(
        extraClassName
      )}`}
      aria-live="polite"
      aria-describedby={ariaDescribedBy}
      data-testid={dataTestId}
    >
      <ErrorIcon size={IconSize.MEDIUM} extraClassName="icon-with-text" />
      {errorMessage}
    </p>
  );
};

export default ButtonErrorMessage;
